import { Box, LinearProgress } from "@mui/material";
import { ElementType, lazy, Suspense } from "react";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const Loadable = (Component: ElementType) => (props: any) =>
  (
    <Suspense
      fallback={
        <Box sx={{ width: "100%", px: 5 }}>
          <LinearProgress />
        </Box>
      }
    >
      <Component {...props} />
    </Suspense>
  );

// AUTH
export const LoginPage = Loadable(
  lazy(() => import("../components/auth/LoginPage"))
);
export const RegisterPage = Loadable(
  lazy(() => import("../components/auth/RegisterPage"))
);
export const VerifyCodePage = Loadable(
  lazy(() => import("../components/auth/VerifyCodePage"))
);
export const NewPasswordPage = Loadable(
  lazy(() => import("../components/auth/NewPasswordPage"))
);
export const ResetPasswordPage = Loadable(
  lazy(() => import("../components/auth/ResetPasswordPage"))
);

// DASHBOARD: GENERAL
export const Home = Loadable(lazy(() => import("../components/Reviews/Home")));
export const ReviewsPage = Loadable(
  lazy(() => import("../components/Reviews/ReviewsPage"))
);

export const SurveySummaryPage = Loadable(
  lazy(() => import("../components/Survey/SurveySummaryPage"))
);
export const Survey = Loadable(
  lazy(() => import("../components/Survey/SurveyWizard"))
);
export const SurveyResults = Loadable(
  lazy(() => import("../components/SurveyResultDetails/SurveyResults"))
);
export const ManagerProfilePage = Loadable(
  lazy(() => import("../components/ManagerProfile/ManagerProfilePage"))
);
export const GoalPage = Loadable(
  lazy(() => import("../components/Goals/GoalPage"))
);
export const GoalBuilderPage = Loadable(
  lazy(() => import("../components/Goals/GoalBuilderPage"))
);
export const StarLeaderValuesPage = Loadable(
  lazy(() => import("../components/StarLeaderValues/StarLeaderValuesPage"))
);
export const StarLeaderCookiePolicyPage = Loadable(
  lazy(
    () => import("../components/StarLeaderValues/StarLeaderCookiePolicyPage")
  )
);
export const StarLeaderPrivacyPolicyPage = Loadable(
  lazy(
    () => import("../components/StarLeaderValues/StarLeaderPrivacyPolicyPage")
  )
);
export const StarLeaderLegalNoticePage = Loadable(
  lazy(() => import("../components/StarLeaderValues/StarLeaderLegalNoticePage"))
);
export const StarLeaderCommitmentsPage = Loadable(
  lazy(() => import("../components/StarLeaderValues/StarLeaderCommitmentsPage"))
);
export const SurveyLandingPage = Loadable(
  lazy(() => import("../components/StarLeaderValues/SurveyLandingPage"))
);
export const HRStatsPage = Loadable(
  lazy(() => import("../components/HumanResources/HRStatsPage"))
);
export const CampaignsPage = Loadable(
  lazy(() => import("../components/Campaigns/CampaignsPage"))
);
export const NewCampaignPage = Loadable(
  lazy(() => import("../components/Campaigns/NewCampaignPage"))
);

export const AdminPage = Loadable(
  lazy(() => import("../components/Admin/AdminPage"))
);

// MAIN
export const Page500 = Loadable(
  lazy(() => import("components/errorPages/Page500"))
);
export const Page403 = Loadable(
  lazy(() => import("components/errorPages/Page403"))
);
export const Page404 = Loadable(
  lazy(() => import("components/errorPages/Page404"))
);
export const MaintenancePage = Loadable(
  lazy(() => import("components/errorPages/MaintenancePage"))
);
