import { GoalTrackPage } from "components/Goals/GoalTrackPage";
import { PATH_AFTER_LOGIN } from "config-global";
import CompactLayout from "layouts/compact";
import { Navigate, useRoutes } from "react-router-dom";

import AuthGuard from "../auth/AuthGuard";
import GuestGuard from "../auth/GuestGuard";
// import HrGuard from "../auth/HrGuard";
import DashboardLayout from "../layouts/dashboard";
import {
  AdminPage,
  CampaignsPage,
  GoalBuilderPage,
  GoalPage,
  Home,
  HRStatsPage,
  LoginPage,
  MaintenancePage,
  ManagerProfilePage,
  NewCampaignPage,
  NewPasswordPage,
  Page403,
  Page404,
  Page500,
  ResetPasswordPage,
  ReviewsPage,
  StarLeaderCommitmentsPage,
  StarLeaderCookiePolicyPage,
  StarLeaderLegalNoticePage,
  StarLeaderPrivacyPolicyPage,
  StarLeaderValuesPage,
  Survey,
  SurveyLandingPage,
  SurveyResults,
  SurveySummaryPage,
} from "./elements";
import { PATH_DASHBOARD } from "./paths";

export default function Router() {
  return useRoutes([
    {
      path: "auth",
      children: [
        {
          path: "login",
          element: (
            <GuestGuard>
              <LoginPage />
            </GuestGuard>
          ),
        },
        // {
        //   path: "register",
        //   element: (
        //     <GuestGuard>
        //       <RegisterPage />
        //     </GuestGuard>
        //   ),
        // },
        // { path: "login-unprotected", element: <LoginPage /> },
        // { path: "register-unprotected", element: <RegisterPage /> },
        {
          element: <CompactLayout />,
          children: [
            { path: "reset-password", element: <ResetPasswordPage /> },
            { path: "new-password", element: <NewPasswordPage /> },
            // { path: "verify", element: <VerifyCodePage /> },
          ],
        },
      ],
    },

    // Dashboard
    {
      path: "/",
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        {
          path: "app",
          element: <Home />,
        },
        { path: "reviews", element: <ReviewsPage /> },
        {
          path: "reviews/wizard/:id/manager/:managerId/:surveyResultId",
          element: <Survey />,
        },
        {
          path: "reviews/wizard/:id/peer/:peerId/:surveyResultId",
          element: <Survey />,
        },
        {
          path: "reviews/wizard/:id/manager/:managerId/campaignId/:campaignId",
          element: <Survey />,
        },
        {
          path: "reviews/wizard/:id/peer/:peerId/campaignId/:campaignId",
          element: <Survey />,
        },
        { path: "reviews/summary/:id", element: <SurveySummaryPage /> },
        { path: "reviews/:id", element: <SurveyResults /> },

        { path: "starLeaderValues", element: <StarLeaderValuesPage /> },
        {
          path: "starLeaderCookiePolicy",
          element: <StarLeaderCookiePolicyPage />,
        },
        {
          path: "starLeaderPrivacyPolicy",
          element: <StarLeaderPrivacyPolicyPage />,
        },
        {
          path: "starLeaderLegalNotice",
          element: <StarLeaderLegalNoticePage />,
        },
        { path: "surveyLandingPage", element: <SurveyLandingPage /> },

        { path: "goals", element: <GoalPage /> },
        { path: "goals/builder/*", element: <GoalBuilderPage /> },
        { path: "goals/track/:id", element: <GoalTrackPage /> },

        { path: PATH_DASHBOARD.general.admin, element: <AdminPage /> },
        // { path: "chat", element: <SurveyResults /> },

        { path: "campaigns", element: <CampaignsPage /> },
        { path: "campaigns/new", element: <NewCampaignPage /> },

        {
          path: PATH_DASHBOARD.general.summary,
          element: <ManagerProfilePage />,
        },
        {
          path: PATH_DASHBOARD.general.companyStats,
          element: <HRStatsPage />,
        },
        {
          path: "starLeaderCommitments",
          element: <StarLeaderCommitmentsPage />,
        },
      ],
    },

    {
      element: <CompactLayout />,
      children: [
        { path: "maintenance", element: <MaintenancePage /> },
        { path: "500", element: <Page500 /> },
        { path: "404", element: <Page404 /> },
        { path: "403", element: <Page403 /> },
      ],
    },
    { path: "*", element: <Navigate to="/404" replace /> },
  ]);
}
